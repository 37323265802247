import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { scrollToTop } from "../common/Header/helper";
import { Icon } from '../../globals/icons';
import { StyledTitle3B, StyledSubTitle2, StyledDesc1 } from "../shared/StyledTypography";
import { removePaddBottom, flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledHasConCOCWrapper = styled.div`
  .zIndex {
    z-index: 1;
  }
  .twoColumnGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px 70px;
    padding-bottom: 80px;
  }
  ${mq.between("md", "lg")} {
    .twoColumnGrid {
      grid-gap: 40px 32px;
      padding-bottom: 60px;
    }
  }
  ${mq.below.md} {
    .twoColumnGrid {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      padding-bottom: 40px;
    }
  }
`;

const CocTopBanner = (props) => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledHasConCOCWrapper>
          <div className="zIndex">
            <Link to={`/events/hasura-con-${props.currentYear ? props.currentYear : "2022"}/`} onClick={scrollToTop}>
            <StyledDesc1 fontWeight="font_600" css={flexCenter} paddingBottom="pb16">
              <Icon className="leftIcon" variant="arrow_left" color="sky_80" size="md" />
              Back to Homepage
            </StyledDesc1>
            </Link>
            <StyledTitle3B paddingBottom="pb32">Code of Conduct</StyledTitle3B>
            <StyledSubTitle2 fontWeight="font_500" paddingBottom="pb40">
              All attendees, speakers, sponsors and volunteers at HasuraCon {props.currentYear ? props.currentYear : "2022"} are required to agree
              with the following code of conduct. Organisers will enforce this code throughout the
              event. We expect cooperation from all participants to help ensure a safe environment for
              everybody.
              <br />
              <br />
              In case you have identified a breach of our CoC, please send an email addressed to{" "}
              <b>Rajoshi Ghosh</b> with the subject{" "}
              <b>Reporting CoC Violation for HasuraCon {props.currentYear ? props.currentYear : "2022"}</b> immediately
              to <a href="mailto:community@hasura.io">community@hasura.io</a>.
            </StyledSubTitle2>
            <StyledSubTitle2 paddingBottom="pb24">The Short Version</StyledSubTitle2>
            <div className="twoColumnGrid">
              <StyledDesc1>
                Our conference is dedicated to providing a harassment-free conference experience for
                everyone, regardless of gender, gender identity and expression, age, sexual orientation,
                disability, physical appearance, body size, race, ethnicity, religion (or lack thereof),
                or technology choices. We do not tolerate harassment of conference participants in any
                form.
              </StyledDesc1>
              <StyledDesc1>
                Sexual language and imagery is not appropriate for any conference venue, including
                talks, workshops, parties, Twitter and other online media. Conference participants
                violating these rules may be expelled from the conference without a refund at the
                discretion of the conference organisers.
              </StyledDesc1>
            </div>

            <StyledSubTitle2 paddingBottom="pb24">The Long Version</StyledSubTitle2>
            <div className="twoColumnGrid" css={removePaddBottom}>
              <StyledDesc1>
                Harassment includes offensive verbal comments related to gender, gender identity and
                expression, age, sexual orientation, disability, physical appearance, body size, race,
                ethnicity, religion, technology choices, sexual images in public spaces, deliberate
                intimidation, stalking, following, harassing photography or recording, sustained
                disruption of talks or other events, inappropriate physical contact, and unwelcome sexual
                attention.
                <br />
                <br />
                Participants asked to stop any harassing behavior are expected to comply immediately.
                <br />
                <br />
                Sponsors are also subject to the anti-harassment policy. In particular, sponsors should
                not use sexualised images, activities, or other material. Booth staff (including
                volunteers) should not use sexualised clothing/uniforms/costumes, or otherwise create a
                sexualised environment.
                <br />
                <br />
                If a participant engages in harassing behavior, the conference organisers may take any
                action they deem appropriate, including warning the offender or expulsion from the
                conference with no refund.
              </StyledDesc1>
              <StyledDesc1>
                If you are being harassed, notice that someone else is being harassed, or have any other
                concerns, please contact a member of conference staff immediately - please see the section
                below on how to report CoC violations.
                <br />
                <br />
                Conference staff will be happy to assist those experiencing harassment to feel safe for
                the duration of the conference. We value your attendance.
                <br />
                <br />
                We expect participants to follow these rules at all times during conference events and
                workshop venues and conference-related social events.
                <br />
                <br />
                Original source and credit:{" "}
                <a href="http://2012.jsconf.us/#/about" target="_blank" rel="noopener noreferrer">
                  http://2012.jsconf.us/#/about
                </a>{" "}
                &{" "}
                <a
                  href="https://geekfeminism.wikia.org/wiki/Conference_anti-harassment/Policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Ada Initiative
                </a>
                <br />
                <br />
                This work is licensed under a Creative Commons Attribution 3.0 Unported License
              </StyledDesc1>
            </div>

          </div>
        </StyledHasConCOCWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default CocTopBanner;
