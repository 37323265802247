import React from "react";

import Seo from "../../../components/seo";
import Layout from "../../../components/hasuracon2022/Layout";
import HeroBanner from "../../../components/hasuracon2022/HeroBanner";
import CocTopBanner from "../../../components/hasuracon2022/CocTopBanner";

const canonicalUrl = "https://hasura.io/events/hasura-con-2022/code-of-conduct/";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022.png",
};

const HasuraCon = props => (
  <Layout location={props.location}>
    <Seo
      title="HasuraCon’22 | Code of Conduct"
      description="Code of Conduct for HasuraCon'22. HasuraCon is the annual Hasura user conference. Bringing the Hasura Team and community together, online."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <HeroBanner location={props.location} />
    <CocTopBanner />
  </Layout>
);

export default HasuraCon;
